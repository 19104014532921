import React, { useEffect, useState } from 'react';
import Dropdown from './Dropdown';
import { Link } from 'react-router-dom';

const MenuItems = () => {
  const [dropdown, setDropdown] = useState(false);
  const [screenSize, setScreenSize] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setScreenSize(window.innerWidth < 960); // Adjust the breakpoint as needed
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const scrollToSection = (sectionId: string) => {
    if (screenSize) {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  const onMouseEnter = () => {
    if (!screenSize) {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (!screenSize) {
      setDropdown(false);
    }
  };

  return (
    <nav className="mt-2">
      <ul className="text-body-1 flex flex-col gap-3 md:flex md:flex-col lg:flex lg:flex-row lg:gap-[1.3rem] cursor-pointer lg:border lg:border-gray-400 lg:border-opacity-25 rounded-full px-[27px] py-[8px]">
        <li className="cursor-pointer">
          <div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} className="group">
            <div onClick={() => scrollToSection('#products')}>
              <p>Products</p>
            </div>
            {dropdown && <Dropdown />}
          </div>
        </li>
        <li>
          <Link to="/company">
            <p>Company</p>
          </Link>
        </li>
        <li>
          <Link to="/contact">
            <p>Contact</p>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuItems;

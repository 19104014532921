import { footerLinks } from '../../constants';
import serializeForm from 'form-serialize';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import config from '../../config';
import { handleRequestErrors } from '../../utils';
import { addNewsletterSubscription } from '../../api/notifications';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();

    const form = ev.target as any;
    const { email } = serializeForm(form, { hash: true });
    setLoading(true);
    addNewsletterSubscription(email)
      .then(() => {
        // eslint-disable-next-line quotes
        toast.success("You've been successfully subscribed to our newsletter!");
        form.reset();
      })
      .catch((err) => handleRequestErrors(err))
      .finally(() => setLoading(false));
  };

  return (
    <footer className="bg-dark-04">
      <div className=" mx-auto sm:px-16 px-[10%] py-6">
        <div></div>
        <div>
          <div className="flex flex-col lg:flex-row lg:justify-between border-t border-white border-opacity-50 pt-[42px]">
            <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start">
              <div>
                <Link to="/">
                  <img src="/assets/logo-primary.png" width={188} height={28} alt="hostcap logo" />
                </Link>
              </div>
              <p className="text-dark-02 text-headline-2 mt-[17px]">Suite 409, Nawa Complex, Jahi, F.C.T, Abuja</p>
              <div className="flex gap-[24px] mt-[23px]">
                <a href={config.socials.instagram} target="_blank" rel="noopener noreferrer">
                  <FaInstagram />
                </a>
                <a href={config.socials.facebook} target="_blank" rel="noopener noreferrer">
                  <FaFacebookF />
                </a>
                <a href={config.socials.twitter} target="_blank" rel="noopener noreferrer">
                  <FaTwitter />
                </a>
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center mt-[45px] lg:mt-0 gap-[43px] lg:gap-[80px]">
              {footerLinks.map((link) => (
                <div key={link.id} className="flex flex-col sm:justify-center sm:items-center lg:justify-start lg:items-start text-center">
                  <h4 className="text-headline-4 text-dark-02">{link.title}</h4>
                  {link.links.map((item) => (
                    <Link key={item.linkTitle} to={item.url} className="text-body-1">
                      <p className="mt-[14px]">{item.linkTitle}</p>
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-between mt-[53px]">
            <div className="lg:w-[444px] border border-white border-opacity-50 rounded-lg p-[23px]">
              <p className="text-headline-2 text-dark-02">News Letter</p>
              <p className="mt-[3px] text-body-1">Stay up to date with our newsletter</p>
              <form
                onSubmit={handleSubmit}
                className="lg:w-[340px] flex justify-between border border-white border-opacity-50 rounded-lg p-[9px] mt-[23px]"
              >
                <input
                  required
                  type="email"
                  placeholder="Email Address"
                  className="!bg-transparent outline-0 w-[280px]"
                  name="email"
                  minLength={5}
                  maxLength={80}
                />
                <button type="submit">
                  {loading ? (
                    '...'
                  ) : (
                    <img src="/assets/arrowIcon.svg" alt="arrow icon" width={40} height={40} className="bg-secondary p-2 rounded-full" />
                  )}
                </button>
              </form>
            </div>
            <div className="flex items-center mt-[17px] lg:mt-0">
              <p className="text-headline-2 text-dark-02">&copy; {currentYear} HostFi Services Ltd. All rights reserved</p>
            </div>
          </div>
          <div className="border-t border-white border-opacity-70 mt-[33px] lg:mt-[65px] pt-[35px]">
            <p className="text-dark-02 text-headline-2">
              Materials on this site are for illustration & discussion purposes only. They are not an offer to, or solicitation of, any
              potential clients or investors for the provision by HostFi Services Ltd of investment management, advisory or any other
              related services. No material listed on this website is or should be construed as investment advice, nor is anything on this
              website an offer to sell, or a solicitation of an offer to buy any cryptocurrency, security or other instruments. An offering
              may be made only by delivery of a confidential offering memorandum to qualified investors. Links from this website to
              third-party websites do not imply any endorsement by the third party of this website or of the link; nor do they imply any
              endorsement by this firm of the third-party website or of the link. Past performance is no guarantee of future results.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import CustomButton from '../../components/CustomButton';
import config from '../../config';
import { Link } from 'react-router-dom';

export default function Company() {
  return (
    <div>
      <section className="relative max-w-[1400px] mx-auto pt-[100px] lg:pt-[160px] sm:px-16 px-[18px] pb-[30px]">
        <div className="flex flex-col justify-center items-center">
          <p className="text-secondary text-center text-body-2 border border-gray-400 border-opacity-30 rounded-full px-[20px] py-[4px]">
            ABOUT US
          </p>
          <h2 className="text-headline-0 lg:w-[636px] lg:text-headline text-center pt-[24px]">Navigating Finance</h2>
          <p className="lg:w-[493px] text-body-1 text-center text-dark-02 pt-[10px]">
            HostCapital is a recognized expert in delivering exceptional opportunities in the financial landscape. We are committed to
            professionalism, integrity, and unwavering dedication to achieving remarkable outcomes.
          </p>
          <img src="/assets/companyImage.svg" alt="hostcap" width={500} height={500} className="mt-[40px]" />
        </div>
      </section>
      <section className="bg-black">
        <div className="flex flex-col lg:flex-row lg:justify-between pt-[35px] lg:pt-0">
          <div className="lg:pl-[100px] 2xl:pl-[250px] lg:mt-[140px] px-[18px] lg:px-0">
            <h2 className="lg:w-[424px] text-headline-1 text-center lg:text-start">Empowering Business Growth</h2>
            <p className="lg:w-[466px] text-body-1 text-white-01 text-center lg:text-start pt-[10px]">
              Our primary goal is to equip businesses like yours with the resources to maximize utility on their assets, offer sustainable
              business opportunities and help businesses surpass their financial expectations.We are a team of seasoned professionals, fully
              equipped with necessary knowledge and skills needed to competently help you navigate through the complexities of financial
              planning, expense management, and many more.
            </p>
            <Link to={config.web.requestAccess} rel="noopener noreferrer">
              <div className="flex gap-2 mt-[20px] justify-center items-center lg:justify-start lg:items-start">
                <CustomButton
                  title="START NOW"
                  btnType="button"
                  containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
                  textStyles="text-button-1"
                />
                <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
              </div>
            </Link>
          </div>
          <div>
            <img src="/assets/ipad.svg" alt="ipad" width={700} height={700} className="lg:mt-[128px] mt-[40px]" />
          </div>
        </div>
      </section>
    </div>
  );
}

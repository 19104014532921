import React from 'react';
import { Countries } from '../../../components';
import { globalSettlement } from '../../../constants';

export default function GlobalSettlement() {
  return (
    <div className=" relative pt-[160px] px-[18px] lg:px-0">
      <div className="flex flex-col justify-center items-center sm:px-16 px-[18px]">
        <p className="text-secondary text-center text-body-2 border border-gray-400 border-opacity-30 rounded-full py-[4px] px-[20px]">
          GLOBAL SETTLEMENT
        </p>
        <h2 className="text-headline-0 lg:w-[689px] lg:text-headline text-center pt-[24px]">
          Make transactions <br /> easily across borders
        </h2>
        <p className="lg:w-[493px] text-body-1 text-center text-dark-02 pt-[10px]">
          Transacting internationally would no longer be a problem once you sign up with us at HostCap
        </p>
        <img src="/assets/exchange.svg" alt="world icon" width={544} height={500} className="pt-[46px]" />
        <p className="lg:w-[493px] text-body-1 text-center text-dark-02 pt-[10px]">
          We have eliminated the traditional hassle of transacting across borders, so now you can transact seamlessly without being
          restricted by geographical locations.
        </p>
      </div>
      <div className="pt-[126px] lg:pt-[226px] sm:px-16 px-[18px]">
        <div className="flex flex-col justify-center items-center">
          <p className="text-secondary text-center text-body-2 border border-gray-400 border-opacity-30 rounded-full px-[14px] py-[7px]">
            USE CASES
          </p>
          <h2 className="lg:w-[487px] text-headline-1 text-center pt-[16px]">Expand your settlement options; transact globally</h2>
          <p className="lg:w-[388px] text-body-1 text-center text-dark-02 pt-[10px]">
            With our global settlement feature, you can streamline international transactions and payments effectively. Our solution
            accelerates the tedious process of traditional banking and eliminates unnecessary delays.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-center lg:items-center lg:gap-[63px] pt-[36px]">
          <img src="/assets/worldIcon.svg" alt="world icon" width={520} height={500} className="animate-pulse" />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[23px] lg:gap-[67px]">
            {globalSettlement.map(({ id, image, title, description }) => (
              <div key={id}>
                <img src={image} alt={title} width={30} height={30} />
                <h3 className="text-headline-3">{title}</h3>
                <p className="lg:w-[296px] text-body-1 text-dark-02">{description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div></div>
      <Countries />
    </div>
  );
}

import React from 'react';
import CustomButton from '../../../components/CustomButton';
import config from '../../../config';
import { hostPlusServices } from '../../../constants';

export default function HostPay() {
  return (
    <div>
      <section className="relative max-w-[1400px] mx-auto pt-[160px]">
        <div className="flex flex-col justify-center items-center sm:px-16 px-[18px]">
          <p className="text-secondary text-center text-body-2 border border-gray-400 border-opacity-30 rounded-full py-[3px] px-[12px]">
            HOST+ PAY
          </p>
          <h2 className="text-headline-0 lg:w-[689px] lg:text-headline text-center pt-[24px]">Simplify Payments</h2>
          <p className="lg:w-[493px] text-body-1 text-center text-dark-02 pt-[10px]">
            Handle transactions, track funds, and streamline your payment process. Accept cryptocurrency payments from customers, increase
            your business revenue, reduce operational costs, and build trust with your customers.
          </p>
          <img src="/assets/select-amount.svg" alt="virtual-card" width={500} height={500} className="pt-[43px]" />
        </div>
      </section>
      <section className="px-[18px] lg:px-[140px] pt-[60px] lg:pt-[120px]">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[40px] lg:gap-[80px] pb-[70px] lg:pb-[140px]">
          {hostPlusServices.map(({ id, image, title, description }) => (
            <div key={id}>
              <img src={image} alt="logo-icon" width={60} height={60} />
              <h3 className="text-headline-3 pt-[23px]">{title}</h3>
              <p className="text-body-2 text-white-01">{description}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="bg-black px-[18px] lg:px-[161px] py-[50px] lg:py-[67px]">
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="lg:pt-[60px] lg:pl-[30px] 2xl:pl-[70px]">
            <h2 className="text-headline-3">What’s More?</h2>
            <p className="lg:w-[466px] text-body-1 text-white-01">
              Host+Pay also empowers you with real-time insights and comprehensive reporting, giving you an overview of your transaction
              activity. Stay informed, track trends, and make data-driven decisions that fuel your business growth.
            </p>
            <a href={config.web.requestAccess} rel="noopener noreferrer">
              <div className="flex gap-1 pt-[30px]">
                <CustomButton
                  title="Start Now"
                  btnType="button"
                  containerStyles="bg-dark-01 px-6 pt-2 pb-3 rounded-full hover:bg-dark-02"
                  textStyles="text-button-1"
                />
                <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
              </div>
            </a>
          </div>
          <div className="pt-[40px] lg:pt-0">
            <img src="/assets/hostCard.svg" alt="host-card icon" width={500} height={500} />
          </div>
        </div>
      </section>
    </div>
  );
}

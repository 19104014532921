import React from 'react';
import CustomButton from '../CustomButton';
import { countries } from '../../constants';
import config from '../../config';

const Countries = () => {
  return (
    <div className="bg-black w-full  mt-[60px] sm:px-16 px-[18px] lg:px-[80px] 2xl:px-[180px]">
      <div className="">
        <div className="flex flex-col lg:flex-row lg:justify-between sm:px-[60px] pt-[40px] lg:pt-[100px] pb-[63px]">
          <div className="lg:ml-[41px] flex flex-col">
            <div className="flex justify-center items-center lg:justify-start lg:items-start">
              <p className="w-[190px] text-secondary text-opacity-70 text-center text-body-2 border border-gray-400 border-opacity-30 rounded-full py-[8px] px-[7px]">
                CURRENCIES SUPPORTED
              </p>
            </div>
            <h2 className="text-headline-3 pt-[12px] text-center lg:text-start">Cross-Border Settlements</h2>
            <p className="lg:w-[416px] text-dark-02 pt-[6px] text-center text-body-2 lg:text-start">
              We offer efficient and secure settlement services. Send money locally and globally, receive payments from international
              customers, and make various transactions internationally without worrying about border barriers.
            </p>
          </div>
          <div>
            <a href={config.web.requestAccess} rel="noopener noreferrer">
              <div className="flex justify-center items-center gap-2 pt-[30px] lg:pt-[70px] lg:mr-[37px]">
                <CustomButton
                  title="GET STARTED"
                  btnType="button"
                  containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
                  textStyles="text-button-1"
                />
                <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 lg:grid-cols-8 gap-[33px] sm:px-16 px-[18px] pb-[97px]">
        {countries.map(({ id, image, title }) => (
          <div key={id} className="flex flex-col justify-center items-center">
            <img src={image} alt={title} width={30} height={30} />
            <p className="text-dark-02 text-headline-2 pt-[8px]">{title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Countries;

import React from 'react';
import { products } from '../../constants';
import CustomButton from '../CustomButton';
import { Link } from 'react-router-dom';

const Offers = () => {
  return (
    <div className=" flex flex-col gap-[15px]">
      {products.map(({ id, title, description, image, url, bgColor }) => (
        <div key={id} className={`${bgColor} relative flex rounded-3xl px-[53px]`}>
          <div className="mt-[44px] mr-[70px]">
            <h3 className="text-headline-3">{title}</h3>
            <p className="lg:w-[533px] text-dark-02 text-body-1">{description}</p>
            <Link to={url}>
              <CustomButton title="LEARN MORE" containerStyles="mt-[34px] pb-[24px]" textStyles="text-primary" />
            </Link>
          </div>
          <img src={image} alt={title} width={150} height={150} className="absolute right-0 bottom-0" />
        </div>
      ))}
    </div>
  );
};

export default Offers;

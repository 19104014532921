import React, { useState } from 'react';
import { HiBars3BottomRight } from 'react-icons/hi2';
import MenuActions from './MenuAction';
import MenuItems from './MenuItems';
import { Link } from 'react-router-dom';

const Header = () => {
  const [open, setOpen] = useState<any | null>(null);
  return (
    <div className="relative">
      {/* mobile screen */}
      <div
        className={`${
          open ? '-translate-x-0' : '-translate-y-full'
        } fixed w-screen bg-dark-04 p-7 transition-all duration-300 ease-in-out filter z-10 lg:hidden`}
      >
        <div className="relatve text-center lg:hidden" onClick={() => setOpen(!open)}>
          <div className="pt-8 pb-2">
            <MenuItems />
          </div>
          <MenuActions />
        </div>
      </div>

      {/* big screen */}
      <div className="fixed w-screen lg:w-full bg-dark-04 flex justify-between sm:px-16 px-[18px] py-3 z-20 ">
        <Link to="/">
          <img src="/assets/logo-primary.png" width={188} height={28} alt="hostcap logo" className="lg:mt-4" />
        </Link>
        <HiBars3BottomRight onClick={() => setOpen(!open)} className="lg:hidden text-3xl text-secondary" />
        <div className="hidden lg:flex">
          <MenuItems />
        </div>
        <div className="hidden lg:flex">
          <MenuActions />
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from 'react';
import Gap from '../../../components/gap';
import { Link } from 'react-router-dom';

export default function LegalPrivacy() {
  return (
    <div className="relative bg-black bg-opacity-70">
      <div className="px-[20px] lg:px-[170px] pt-[80px] lg:pt-[154px] pb-[90px]">
        <ul>
          <li>
            <h3 className="text-headline-3 font-bold pt-5">1. General</h3>
            <p className="text-body-2 text-dark-02">
              HostFi Services Ltd ("us", "we", or "our") operates the websites and application available on https://hostcap.io/ or any of
              its subdomains (the "Service").
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service
              and the choices you have associated with that data.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information
              in accordance with this policy. Unless otherwise defined in this Privacy and Security Policy, terms used in this Privacy and
              Security Policy have the same meanings as in our Terms and Conditions, accessible{' '}
              <Link to="/legal/terms-of-service" className="text-primary">
                here
              </Link>
              .
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">2. Definitions</h3>
            <Gap v={1} />
            <b>Personal Data</b>
            <p className="text-body-2 text-dark-02 pb-3">
              Personal Data means data about a living individual who can be identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </p>
            <b>Usage Data</b>
            <p className="text-body-2 text-dark-02 pb-3">
              Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure
              itself (for example, the duration of a page visit).
            </p>
            <b>Cookies</b>
            <p className="text-body-2 text-dark-02 pb-3">Cookies are small pieces of data stored on a User's device.</p>‍
            <b>Data Controller</b>
            <p className="text-body-2 text-dark-02">
              Data Controller means a person who (either alone or jointly or in common with other persons) determines the purposes for which
              and the manner in which any personal data are, or are to be, processed.
            </p>
            <p className="text-body-2 text-dark-02 pb-3">
              For the purpose of this Privacy and Security Policy, we are a Data Controller of your data.
            </p>
            ‍<b>Data Processor (or Service Providers)</b>
            <p className="text-body-2 text-dark-02">
              Data Processor (or Service Provider) means any person (other than an employee of the Data Controller) who processes the data
              on behalf of the Data Controller.
            </p>
            <p className="text-body-2 text-dark-02 pb-3">
              We may use the services of various Service Providers in order to process your data more effectively.
            </p>
            ‍<b>Data Subject</b>
            <p className="text-body-2 text-dark-02 pb-3">Data Subject is any living individual who is the subject of Personal Data.</p>‍
            <b>User</b>
            <p className="text-body-2 text-dark-02">
              The User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">3. Information Collection & Use</h3>
            <p className="text-body-2 text-dark-02">
              We collect several different types of information for various purposes to provide and improve our Service to you.
            </p>

            <Gap v={1} />
            <i className="text-body-2">Types of Data Collected</i>
            <Gap v={1} />
            <b>Personal Data</b>
            <p className="text-body-2 text-dark-02">
              While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to
              contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
            </p>

            <ul className="ml-5 flex flex-col gap-2 pt-4 list-disc">
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Cookies and Usage Data</li>
            </ul>

            <p className="text-body-2 text-dark-02 pt-1 pb-3">
              We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that
              may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the
              unsubscribe link or instructions provided in any email we send or by contacting us.
            </p>

            <b>Usage Data</b>
            <p className="text-body-2 text-dark-02 pb-3">
              We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information
              such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service
              that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic
              data.
            </p>

            <b>Tracking & Cookies Data</b>
            <p className="text-body-2 text-dark-02">
              We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
            </p>
            <p className="text-body-2 text-dark-02 pt-3">
              Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser
              from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track
              information and to improve and analyze our Service.
            </p>
            <p className="text-body-2 text-dark-02 pt-3">
              You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>

            <p className="text-body-2 text-dark-02 pt-3">Examples of Cookies we use:</p>
            <ul className="pl-5 pt-4 list-disc">
              <li>
                <b>Session Cookies</b>. <span className="text-body-2 text-dark-02">We use Session Cookies to operate our Service.</span>
              </li>
              <li>
                <b>Preference Cookies</b>.{' '}
                <span className="text-body-2 text-dark-02">
                  We use Preference Cookies to remember your preferences and various settings.
                </span>
              </li>
              <li>
                <b>Security Cookies</b>. <span className="text-body-2 text-dark-02">We use Security Cookies for security purposes.</span>
              </li>
            </ul>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">4. Use Of Data</h3>
            <p className="text-body-2 text-dark-02">HostFi Services Ltd uses the collected data for various purposes:</p>
            <ul className="pt-5 list-disc text-body-2 font-semibold text-dark-02 pl-5">
              <li>To provide and maintain our Service</li>
              <li>To notify you about changes to our Service</li>
              <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
              <li>To provide customer support</li>
              <li>To gather analysis or valuable information so that we can improve our Service</li>
              <li>To monitor the usage of our Service</li>
              <li>To detect, prevent and address technical issues</li>
              <li>
                To provide you with news, special offers and general information about other goods, services and events which we offer that
                are similar to those that you have already purchased or enquired about unless you have opted not to receive such
                information.
              </li>
            </ul>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">5. Retention of Data</h3>
            <p className="text-body-2 text-dark-02 pt-2">
              HostFi Services Ltd will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy
              and Security Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations
              (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal
              agreements and policies.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              HostFi Services Ltd will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter
              period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we
              are legally obligated to retain this data for longer time periods.
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">6. Transfer of Data</h3>
            <p className="text-body-2 text-dark-02 pt-2">
              Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your
              state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your
              jurisdiction.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              If you are located outside Nigeria and choose to provide information to us, please note that we transfer the data, including
              Personal Data, to Nigeria and process it there.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              Your consent to this Privacy and Security Policy followed by your submission of such information represents your agreement to
              that transfer.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              HostFi Services Ltd will take all steps reasonably necessary to ensure that your data is treated securely and in accordance
              with this Privacy and Security Policy and no transfer of your Personal Data will take place to an organization or a country
              unless there are adequate controls in place including the security of your data and other personal information.
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold pb-4">7. Disclosure of Law</h3>

            <b>Disclosure for Law Enforcement</b>
            <p className="text-body-2 text-dark-02 pb-3">
              Under certain circumstances, HostFi Services Ltd may be required to disclose your Personal Data if required to do so by law or
              in response to valid requests by public authorities (e.g. a court or a government agency).
            </p>

            <b>Legal Requirements</b>

            <p className="text-body-2 text-dark-02 pt-2">
              HostFi Services Ltd may disclose your Personal Data in the good faith belief that such action is necessary to:
            </p>

            <ul className="list-disc pl-5 pt-5 flex flex-col gap-3 text-body-2 text-dark-02 font-semibold">
              <li>To comply with a legal obligation</li>
              <li>To protect and defend the rights or property of HostFi Services Ltd</li>
              <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
              <li>To protect the personal safety of users of the Service or the public</li>
              <li>To protect against legal liability</li>
            </ul>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">8. Security of Data</h3>
            <p className="text-body-2 text-dark-02">
              The security of your data is important to us, but remember that no method of transmission over the Internet, or method of
              electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we
              cannot guarantee its absolute security.
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">9. "Do Not Track" Signals</h3>
            <p className="text-body-2 text-dark-02">
              We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that
              you do not want to be tracked.
            </p>

            <p className="text-body-2 text-dark-02 pt-3">
              You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">10. Your Rights</h3>
            <p className="text-body-2 text-dark-02">
              HostFi Services Ltd aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal
              Data.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              Whenever made possible, you can update your Personal Data directly within your account settings section. If you are unable to
              change your Personal Data, please contact us to make the required changes.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please
              contact us.
            </p>

            <p className="text-body-2 text-dark-02 pt-3">In certain circumstances, you have the right:</p>

            <ul className="list-disc text-body-2 text-dark-02 font-semibold pl-5 pt-3 pb-3">
              <li>To access and receive a copy of the Personal Data we hold about you</li>
              <li>To rectify any Personal Data held about you that is inaccurate</li>
              <li>To request the deletion of Personal Data held about you</li>
            </ul>

            <p className="text-body-2 text-dark-02">
              You have the right to data portability for the information you provide to HostFi Services Ltd. You can request to obtain a
              copy of your Personal Data in a commonly used electronic format so that you can manage and move it.
            </p>

            <p className="text-body-2 text-dark-02">
              Please note that we may ask you to verify your identity before responding to such requests.
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">11. Service Providers</h3>
            <p className="text-body-2 text-dark-02">
              We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on
              our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>

            <p className="text-body-2 text-dark-02 pt-3 font-semibold">
              <i>Analytics</i>
            </p>

            <p className="text-body-2 text-dark-02 pt-3">
              We may use third-party Service Providers to monitor and analyze the use of our Service.
            </p>

            <ul className="text-body-2 text-dark-02 list-disc pl-5 pt-3 font-semibold">
              <li>Google Analytics</li>
            </ul>

            <p className="text-body-2 text-dark-02 pt-3">
              Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data
              collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the
              collected data to contextualize and personalize the ads of its own advertising network.You can opt-out of having made your
              activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on
              prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about
              visits activity.For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:{' '}
              <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                https://policies.google.com/privacy
              </a>
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">12. Links to Other Sites</h3>
            <p className="text-body-2 text-dark-02">
              Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be
              directed to that third party's site. We strongly advise you to review the Privacy and Security Policy of every site you visit.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites
              or services.
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">13. Children's Privacy</h3>
            <p className="text-body-2 text-dark-02">Our Service does not address anyone under the age of 13 ("Children").</p>

            <p className="text-body-2 text-dark-02 pt-2">
              We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or
              guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that
              we have collected Personal Data from children without verification of parental consent, we take steps to remove that
              information from our servers.
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">14. Changes to this Privacy and Security Policy</h3>
            <p className="text-body-2 text-dark-02">
              We may update our Privacy and Security Policy from time to time. We will notify you of any changes by posting the new Privacy
              and Security Policy on this page.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the
              "effective date" at the top of this Privacy and Security Policy.
            </p>

            <p className="text-body-2 text-dark-02 pt-2">
              You are advised to review this Privacy and Security Policy periodically for any changes. Changes to this Privacy and Security
              Policy are effective when they are posted on this page.
            </p>
          </li>

          <li className="pt-5">
            <h3 className="text-headline-3 font-bold">15. Contact Us</h3>
            <p className="text-body-2 text-dark-02">If you have any questions about this Privacy and Security Policy, please contact us:</p>

            <ul className="list-disc pl-5 pt-2 text-body-2 text-primary font-semibold">
              <li>
                <a href="mailto:help@hostcap.io">By email: help@hostcap.io</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

import React from 'react';
import config from '../../../config';
import { virtualCardServices } from '../../../constants';
import CustomButton from '../../../components/CustomButton';

export default function VirtualCards() {
  return (
    <div className="relative mx-auto pt-[160px]">
      <div className="flex flex-col justify-center items-center sm:px-16 px-[18px]">
        <p className="text-secondary text-center text-body-2 border border-gray-400 border-opacity-30 rounded-full py-[3px] px-[12px]">
          VIRTUAL CARDS
        </p>
        <h2 className="text-headline-0 lg:w-[689px] lg:text-headline text-center pt-[24px]">Extensive selection of card solutions</h2>
        <p className="lg:w-[493px] text-body-1 text-center text-dark-02 pt-[10px]">
          Elevate Your Business Experience With Streamlined Payments, and Enhanced Control. With our virtual cards, businesses can
          effortlessly make online purchases, create multiple cards, manage expenses, and set spending limits.
        </p>
        <img src="/assets/virtualCard.svg" alt="virtual-card" width={434} height={300} className="pt-[43px]" />
      </div>
      <div className="px-[18px] lg:px-[161px] pt-[35px] lg:pt-[95px]">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[90px]">
          {virtualCardServices.map(({ id, image, title, content }) => (
            <div key={id}>
              <img src={image} alt="img" width={50} height={50} />
              <h3 className="text-headline-3">{title}</h3>
              <p className="text-body-2 text-white-01">{content}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-black sm:px-16 px-[18px] mt-[97px]">
        <div className="flex flex-col lg:flex-row lg:justify-around gap-2 lg:gap-0 pt-[50px] lg:pt-[100px] pb-[50px] lg:pb-[100px]">
          <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start lg:pt-6">
            <h3 className="lg:w-[424px] text-headline-3">Supported Virtual Cards</h3>
            <div className="flex flex-col gap-[18px] pt-[13px]">
              <div className="flex gap-[8px]">
                <img src="/assets/check-circle-colored.svg" alt="check icon" width={20} height={20} />
                <p className="text-body-2 text-white-01">USD Virtual Cards</p>
              </div>
              <div className="flex gap-[8px]">
                <img src="/assets/check-circle-colored.svg" alt="check icon" width={20} height={20} />
                <p className="text-body-2 text-white-01">
                  NGN Cards: Virtual and Physical Cards
                  <span className="text-secondary text-body-2 text-opacity-70 pl-[8px]">COMING SOON</span>
                </p>
              </div>
              <div className="flex gap-[8px]">
                <img src="/assets/check-circle-colored.svg" alt="check icon" width={20} height={20} />
                <p className="text-body-2 text-white-01">
                  USD Cards: Physical Cards
                  <span className="text-secondary text-body-2 text-opacity-70 pl-[8px]">COMING SOON</span>
                </p>
              </div>
              <div className="flex gap-[8px]">
                <img src="/assets/check-circle-colored.svg" alt="check icon" width={20} height={20} />
                <p className="text-body-2 text-white-01">
                  EUR Cards: Virtual and Physical Cards
                  <span className="text-secondary text-body-2 text-opacity-70 pl-[8px]">COMING SOON</span>
                </p>
              </div>
              <div className="flex gap-[8px]">
                <img src="/assets/check-circle-colored.svg" alt="check icon" width={20} height={20} />
                <p className="text-body-2 text-white-01">
                  GBP Cards: Virtual and Physical Cards
                  <span className="text-secondary text-body-2 text-opacity-70 pl-[8px]">COMING SOON</span>
                </p>
              </div>
            </div>
            <a href={config.web.requestAccess} rel="noopener noreferrer">
              <div className="flex gap-2 pt-[30px]">
                <CustomButton
                  title="Start Now"
                  btnType="button"
                  containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
                  textStyles="text-button-1"
                />
                <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
              </div>
            </a>
          </div>
          <div>
            <img src="/assets/mCardIcon.svg" alt="card icon" width={400} height={400} />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import CustomButton from '../CustomButton';
import config from '../../config';

const JoinUs = () => {
  return (
    <div className="max-w-[1440px] mx-auto px-[24px] lg:px-[133px] pb-[114px] lg:pb-[153px] pt-[56px] lg:pt-[127px]">
      <h2 className="text-center text-headline-1">Joining Us Is Easy</h2>
      <p className="lg:w-[523px] mx-auto  text-center text-body-1 text-dark-02 mt-[13px]">
        We offer best in class pricing and bespoke solutions. Follow these steps to get started.
      </p>
      <div className="bg-dark-04 border border-gray-400 border-opacity-30 rounded-xl mt-[38px]">
        <div className="flex">
          <div className="lg:w-[50%] px-[32px] lg:px-[78px] py-[39px] lg:py-[142px]">
            <p className="text-dark-02">01.</p>
            <h3 className="text-headline-3 mt-[8px]">Request Access</h3>
            <p className="text-body-2 text-dark-02 mt-[8px]">
              When you request access, we would contact you to know how best we can serve you, what your requirements are, and how we can
              meet them. After requesting access, you will be assigned an asset manager to assist you with your requirements.
            </p>
            <a href={config.web.requestAccess} rel="noopener noreferrer">
              <div className="flex gap-1 mt-[32px]">
                <CustomButton
                  title="GET STARTED"
                  btnType="button"
                  containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
                  textStyles="text-button-1"
                />
                <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
              </div>
            </a>
          </div>
          <div className="relative bg-graph bg-opacity-10 bg-cover w-0 lg:w-[50%]">
            <img src="/assets/connect.svg" alt="connect icon" width={400} height={400} className="absolute top-[25%] left-[15%]" />
          </div>
        </div>
      </div>
      <div className="mt-[24px] lg:mt-[58px]">
        <div className="flex flex-col lg:flex-row gap-[24px]">
          <div className="relative bg-dark-04 px-[32px] lg:px-[52px] border border-gray-400 border-opacity-30 rounded-xl">
            <img src="/assets/connection.svg" alt="connet" width={300} height={300} className="hidden lg:block lg:absolute lg:right-[2%]" />
            <p className="text-dark-02 text-headline-2 mt-[39px] lg:mt-[242px]">02.</p>
            <h3 className="text-headline-3 mt-[8px]">Setup Your Account</h3>
            <p className=" text-dark-02 text-body-2">
              Your Asset manager will assist you with setting up your account through a personalized onboarding process. You will be
              required to provide and full necessary documents either offline, or online.
            </p>
            <div className="flex gap-1 mt-[12px] pb-[38px]">
              <a href={config.web.requestAccess} rel="noopener noreferrer">
                <div className="flex gap-1 mt-[32px] pb-[38px]">
                  <CustomButton
                    title="GET STARTED"
                    btnType="button"
                    containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
                    textStyles="text-button-1"
                  />

                  <img src="/assets/arrowIcon.svg" alt="arrow icon" width={40} height={30} className="bg-primary p-[6px] rounded-full" />
                </div>
              </a>
            </div>
          </div>
          <div className="relative bg-dark-04 px-[32px]  lg:px-[52px] border border-gray-400 border-opacity-30 rounded-xl">
            <img src="/assets/lineChart.svg" alt="chart icon" width={300} height={300} className="hidden lg:block lg:absolute lg:right-0" />
            <p className="mt-[24px] lg:mt-[68px] text-dark-02 text-headline-2">03.</p>
            <h3 className="mt-[8px] text-headline-3">Start Transacting:</h3>
            <p className=" text-dark-02 text-body-2 mt-[8px]">
              Once your account is activated, you can start transacting and exploring a variety of trading opportunities. You can also reach
              out to your asset manager anytime you want to initiate a transaction.
            </p>
            <a href={config.web.requestAccess} rel="noopener noreferrer">
              <div className="flex gap-1 mt-[32px] pb-[38px]">
                <CustomButton
                  title="GET STARTED"
                  btnType="button"
                  containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
                  textStyles="text-button-1"
                />
                <img src="/assets/arrowIcon.svg" alt="arrow icon" width={40} height={30} className="bg-primary p-[6px] rounded-full" />
              </div>
            </a>
            <img
              src="/assets/activityChart.svg"
              alt="chart icon"
              width={300}
              height={300}
              className="hidden lg:block lg:absolute lg:bottom-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;

import React from 'react';
import CustomButton from '../../../components/CustomButton';
import config from '../../../config';
import { walletServices } from '../../../constants';

export default function Waas() {
  return (
    <div className="relative pt-[160px]">
      <div className="flex flex-col justify-center items-center sm:px-16 px-[18px]">
        <p className="text-secondary text-center text-body-2 border border-gray-400 border-opacity-30 rounded-full px-[20px] py-[4px]">
          WALLETS AS A SERVICE
        </p>
        <h2 className="text-headline-0 lg:w-[689px] lg:text-headline text-center pt-[24px]">
          Store, manage, and transact digital assets effectively
        </h2>
        <p className="lg:w-[413px] text-body-1 text-center text-dark-02 pt-[10px]">
          We provide you with a platform to securely manage your digital assets, ensure your investments are safe, manage your
          cryptocurrency, and enable you to carry out transactions easily
        </p>
        <a href={config.web.requestAccess}>
          <CustomButton
            title="View API Documentation"
            containerStyles="bg-primary mt-[32px] px-[27px] py-[21px] rounded-full"
            textStyles="text-white"
          />
        </a>
      </div>
      <div className=" max-w-[1400px] mx-auto flex flex-col lg:flex-row gap-[80px] lg:gap-[180px] pt-[75px] lg:pt-[153px] px-[18px] lg:px-0">
        <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start lg:ml-[90px]">
          <p className=" lg:w-[121px] text-secondary text-center text-body-2 border border-gray-400 border-opacity-30 rounded-full px-[14px] lg:px-[14px] py-[11px]">
            Get Started
          </p>
          <h2 className="lg:w-[324px] text-headline-3 text-center lg:text-start pt-[16px]">Control Your Finances</h2>
          <p className="lg:w-[520px] text-white-01 text-body-2">
            Our Wallets-As-A-Service API allows you to develop and manage your digital wallets through our easy API integration process.
            With our WAAS API, businesses can create their own cryptocurrency exchange wallets, make direct fiat withdrawals, accept crypto
            payments, track rates and do a lot more.
          </p>
          <a href={config.web.requestAccess} rel="noopener noreferrer">
            <div className="flex gap-1 pt-[30px]">
              <CustomButton
                title="Start Now"
                btnType="button"
                containerStyles="bg-dark-01 px-6 pt-2 pb-3 rounded-full hover:bg-dark-02"
                textStyles="text-button-1"
              />
              <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
            </div>
          </a>
        </div>
        <div className="relative">
          <div className="lg:mr-[20px]">
            <img src="/assets/waasImage.svg" alt="api" width={400} height={400} />
          </div>
          <img
            src="/assets/ballIcon.svg"
            alt="round icon"
            width={350}
            height={300}
            className="absolute top-[40%] lg:top-[20%] right-[4%] lg:right-[-40%]"
          />
        </div>
      </div>
      <div className="bg-black mt-[150px] mx-[8px] lg:mx-[10px] 2xl:mx-[15px] pt-[30px] lg:pt-[80px] pb-[23px] lg:pb-[37px] rounded-2xl">
        <div className="flex flex-col justify-center items-center">
          <h2 className="lg:w-[349px] text-center text-headline-1">One Wallet, Endless Possibilities</h2>
          <p className="text-white-01 text-body-1 pt-[5px]">Enjoy efficient wallet management and financial control</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[30px] lg:gap-[30px] 2xl:gap-[50px] pt-[26px] lg:pt-[80px] px-[18px] lg:px-[100px]">
          {walletServices.map(({ id, image, title, description }) => (
            <div key={id} className="">
              <div className="flex justify-center items-center lg:justify-start lg:items-start">
                <img src={image} alt="logo" width={50} height={50} />
              </div>
              <h3 className="text-headline-3 text-center lg:text-start">{title}</h3>
              <p className="text-body-2 text-white-01">{description}</p>
            </div>
          ))}
        </div>
        <div className="flex justify-center items-center">
          <a href={config.web.requestAccess} rel="noopener noreferrer">
            <div className="flex gap-1 pt-[76px]">
              <CustomButton
                title="Start Now"
                btnType="button"
                containerStyles="bg-dark-01 px-6 pt-2 pb-3 rounded-full hover:bg-dark-02"
                textStyles="text-button-1"
              />
              <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

export default function PolicyAml() {
  return (
    <div className="bg-black bg-opacity-70">
      <div className="px-[20px] lg:px-[170px] pt-[80px] lg:pt-[154px] pb-[90px]">
        <h3 className="text-headline-1 lg:text-headline-0 text-center lg:text-start font-bold pb-5">
          Anti-Money Laundering & Combating the Financing of Terrorism (AML/CFT) Policy
        </h3>

        <ul>
          <li>
            <h4 className="text-headline-3 font-bold">1. Policy</h4>
            <p className="text-body-2 text-dark-02">
              This Anti-Money Laundering &amp; Combating the Financing of Terrorism Policy (“AMF/CFT Policy”) sets out the guidelines for
              HostFi Services Ltd’s compliance with AML/CFT obligations under the law, as well as regulatory directives, and actively
              prevents any transaction that facilitates criminal activities.
            </p>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">2. Description</h4>
            <p className="text-body-2 text-dark-02">
              Money laundering and financing terrorism are financial crimes with economic effects. It requires an underlying primary
              profit-making crime such as corruption, drug trafficking, market manipulation, fraud, tax evasion, etc., with the intent to
              conceal the proceeds of the crime and to further the criminal enterprise. AML/CFT compliance programmes help mitigate the
              adverse effects of criminal economic activity and promote integrity and stability in financial markets. It is also critical to
              preserving HostFi Services Ltd’s corporate integrity, reputation and operational efficiency.
            </p>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">3. Definitions</h4>
            <ul className="list-disc pl-5 flex flex-col gap-2">
              <li>
                <strong>Money Laundering</strong> -{' '}
                <span className="text-body-2 text-dark-02">
                  Money laundering (ML) has been defined as the process whereby criminals attempt to conceal the illegal origin and/or
                  illegitimate ownership of property and assets that are the proceeds of their criminal activities. It is, thus, a
                  derivative crime (Central Bank of Nigeria Manual).
                </span>
              </li>
              <li>
                <strong>Terrorism Financing</strong> -{' '}
                <span className="text-body-2 text-dark-02">
                  includes both legitimate and illegitimate money characterised by concealment of the origin or intended criminal use of the
                  funds.
                </span>
              </li>
              <li>
                <strong>Know Your Customer</strong> -{' '}
                <span className="text-body-2 text-dark-02">
                  This entails obtaining and verifying customer identity, preservation of records of customers, mandatory disclosure of
                  transactions to authorised statutory bodies.
                </span>
              </li>
              <li>
                <strong>Customer Due Diligence</strong> -{' '}
                <span className="text-body-2 text-dark-02">
                  This covers steps taken by HostFi Services Ltd to identify its clients and validate their identities.
                </span>
              </li>
              <li>
                <strong>Nigeria Financial Intelligence Unit</strong> -{' '}
                <span className="text-body-2 text-dark-02">The Nigerian arm of the global Financial Intelligence Unit (FIU).</span>
              </li>
              <li>
                <strong>Politically Exposed Persons</strong> -{' '}
                <span className="text-body-2 text-dark-02">
                  Individuals who are or have been entrusted with prominent public functions in any country; generally presenting a higher
                  risk for potential involvement in bribery and corruption by virtue of their position and the influence that they may hold.
                </span>
              </li>
            </ul>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">4. Purpose</h4>
            <p className="text-body-2 text-dark-02 font-semibold">The purpose of this Policy manual is to:</p>
            <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
              <li>
                Provide guidance on the standards of conduct and practice that must be followed in the implementation of the Know Your
                Customer (KYC) and Customer Due Diligence (CDD) requirements for HostFi Services Ltd’s transactions
              </li>
              <li>Protect HostFi Services Ltd against fraud, reputational and other financial market risks</li>
              <li>Minimise the risks faced by HostFi Services Ltd from proceeds of crime</li>
              <li>
                Protect the integrity of the Nigerian financial services market against all forms of abuse, fraudulent and unfair trade
                practices
              </li>
            </ol>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">5. Policy Statement</h4>
            <p className="text-body-2 text-dark-02 font-semibold">
              The following statements shall guide the principles and procedures for AML/CFT compliance programme. HostFi Services Ltd
              shall:
            </p>
            <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
              <li>
                Formulate and implement internal controls and other procedures that will deter criminals from using its facilities for money
                laundering and terrorist financing and to ensure that its obligations under subsisting laws and Regulations are met
              </li>
              <li>
                Designate AML/CFT Chief Compliance Officer at the Management level, with the relevant competence, authority and independence
                to implement the institution’s AML/CFT compliance programme
              </li>
              <li>
                Comply with the requirements of the Money Laundering (Prohibition) Act, 2011 (as amended), Terrorism (Prevention) Act, 2011
                (as amended) and Terrorism Prevention (Freezing of International Terrorists Funds and other Related Measures) Regulations
                2013, Central Bank of Nigeria (Anti-Money laundering and Combating the Financing of Terrorism for Banks and Other Financial
                Institutions in Nigeria) Regulations, 2013 including related laws and Regulations
              </li>
              <li>
                Comply promptly with all the requests made pursuant to subsisting laws and Regulations and shall provide relevant
                information to the Central Bank of Nigeria (CBN), the Nigerian Financial Intelligence Unit (NFIU) and other relevant law
                enforcement agencies on AML/CFT matters
              </li>
              <li>
                Identify and report to the NFIU, in the course of its business, any suspicious transactions derived from the criminal
                activities defined in AML/CFT CBN Regulations
              </li>
              <li>
                Ensure the implementation of the requirements of the AML/CFT Act are not inhibited through HostFi Services Ltd’s
                Confidentiality Agreement or Policy
              </li>
              <li>Exit relationships which pose heightened money laundering risks to HostFi Services Ltd</li>
              <li>Effectively communicate this Policy to raise the level of staff awareness on AML/CFT issues</li>
            </ol>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">6. Know Your Customer (KYC)</h4>
            <p className="text-body-2 text-white pt-2 font-semibold">
              KYC is the due diligence that Financial Institutions, Financial Market Operators, Capital Market Operators, Financial Market
              Infrastructures and other regulated companies must perform to identify their clients and ascertain relevant information before
              carrying-out any financial businesses with them. A client for the purposes of KYC policy is defined as:
            </p>
            <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
              <li>An entity that has a business relationship with HostFi Services Ltd</li>
              <li>
                Any entity connected with a financial transaction, which can pose a significant reputational or other risk to HostFi
                Services Ltd
              </li>
            </ol>
            <p className="text-body-2 text-white font-semibold pt-3">
              Specifically, HostFi Services Ltd’s KYC policies and procedures emphasise the following:
            </p>
            <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
              <li>
                Obtaining the necessary documents and information from every client (anyone who HostFi Services Ltd receives money from to
                provide a business)
              </li>
              <li>
                Obligation to report to the regulatory authorities on suspicious transactions, which may ultimately have a bearing on money
                laundering activities
              </li>
              <li>Updating client information as frequently as practicable</li>
              <li>
                Identifying the client as well as their beneficial owners and verifying such client’s identity using reliable, independent
                source documents, data or information
              </li>
              <li>
                Independent verification of legal status of incorporated entities and sole proprietorships with the Corporate Affairs
                Commission, in writing.
              </li>
              <li>Refusal to transact business with “shell companies” as described under the International Conventions</li>
              <li>
                Performing enhanced due diligence for higher risk clients, business relationships or transactions including:
                <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
                  <li>Politically Exposed Persons (PEP), cross border transactions and business relationships</li>
                  <li>
                    Any other businesses, activities or professions as may be prescribed by regulatory, supervisory and competent
                    authorities
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">7. Record Keeping and Retention Requirements</h4>
            <p className="text-bosy-2 text-dark-02">
              Upon request by a regulatory or law enforcement agency, HostFi Services Ltd shall make available records related to AML/CFT
              compliance or its clients as soon as possible from the date of the request.
            </p>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">8. Transaction Reporting</h4>
            <p className="text-body-2 text-dark-02">
              HostFi Services Ltd shall exercise due diligence in identifying and reporting a suspicious transaction. Suspicious
              transactions shall include:
            </p>
            <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
              <li>Transactions which are structured to avoid reporting and record keeping requirements</li>
              <li>
                Altered or false identification or inconsistent information or any transaction involving criminal activity in HostFi
                Services Ltd’s view
              </li>
              <li>Entity that belongs to a person or organisation considered as terrorist</li>
            </ol>
            <p className="text-body-2 text-dark-02 pt-3">
              HostFi Services Ltd shall ensure timely and accurate rendition of all AML/CFT returns as specified in relevant
              Regulations/Act/Guidelines/Circulars that may be issued from time to time by various government agencies.
            </p>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">9. Awareness and Training</h4>
            <p className="text-body-2 text-dark-02">
              HostFi Services Ltd shall create awareness amongst its employees on AML/CFT through a robust training program that will
              include formal courses, workshops and newsletters. Such trainings shall incorporate current developments and changes to
              relevant guidelines as well as internal Policies, procedures, processes and monitoring systems. HostFi Services Ltd shall also
              utilise other avenues such as e-mails, laptop screensavers, display screens, posters etc. to disseminate compliance issues
              arising from new rules and regulations to all Staff members.
            </p>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">10. Politically Exposed Persons (PEPs)</h4>
            <p className="text-body-2 text-dark-02">
              Business relationships with family members or close associates of PEPs involve reputation risks similar to those PEPs
              themselves. HostFi Services Ltd shall evaluate the risks to its business operations when dealing with PEPs. The following
              factors shall guide identification of PEPs’ risk characteristics:
            </p>
            <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
              <li>
                Nature of the client and the client’s businesses - The source of the client’s wealth, the nature of the client’s business
                and the extent to which the client’s business history presents an increased risk for money-laundering and terrorist
                financing
              </li>
              <li>Purpose and activity – The size, purpose, services involved in the relationship</li>
              <li>Relationship – The nature and duration of HostFi Services Ltd’s relationship with the client</li>
              <li>Client’s corporate structure</li>
              <li>Public information – Information is known or reasonably available to HostFi Services Ltd about the client</li>
            </ol>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">11. Responsibilities</h4>
            <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
              <li>
                HostFi Services Ltd’s Board of Directors is responsible for:
                <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
                  <li>Approving the AML/CFT Compliance Policy</li>
                  <li>Reviewing all periodic report on AML/CFT matters</li>
                  <li>Ensuring that Management forwards all required periodic reports to the relevant regulatory authorities</li>
                  <li>Providing guidelines regarding the management of AML/CFT compliance risks.</li>
                </ol>
              </li>
              <li>
                HostFi Services Ltd’s Compliance Officer is responsible for:
                <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
                  <li>Coordinating and monitoring day-to-day compliance with applicable money laundering laws and regulations.</li>
                  <li>Monitoring transactions to detect unusual suspicious activities</li>
                  <li>
                    Prompt preparation and delivery of all relevant returns to the regulatory bodies in line with CBN and FIU Rules and
                    Regulations
                  </li>
                  <li>Communicating AML/CFT issues to all stakeholders</li>
                </ol>
              </li>
              <li>
                All Staff Members:
                <ol className="list-decimal pl-5 flex flex-col gap-2 pt-3 text-body-2 text-dark-02">
                  <li>
                    Familiarising themselves with guidelines, policies and best practices relating to their respective areas of
                    responsibility
                  </li>
                  <li>Implementing the measures and approaches diligently and to the best of their ability</li>
                  <li>
                    Reporting any legal violations or other forms of misconduct in accordance with HostFi Services Ltd Policies and
                    Procedures
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">12. Scope</h4>
            <p className="text-body-2 text-dark-02">
              This Policy manual applies to HostFi Services Ltd and its Board of Directors, Management and Staff with respect to compliance
              with rules and regulations that govern its operations. Specifically, the AML/CFT Compliance Procedures shall be incorporated
              into the operations of Business Marketing, Business Origination, Market Regulation, Examination Groups, as well as any other
              relevant Groups in HostFi Services Ltd.
            </p>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">13. Consequences</h4>
            <p className="text-body-2 text-dark-02">
              A breach of the anti-money laundering and combating the financing of terrorism laws is a serious offence and could result in
              lengthy investigations, significant fines and criminal sanctions (including imprisonment of employees).
            </p>
          </li>

          <li className="pt-5">
            <h4 className="text-headline-3 font-bold">14. Contact Information</h4>
            <p className="text-body-2 text-dark-02">
              If you have any questions or comments about these our policy as outlined above, you can contact us at:
              <a href="mailto:help@hostcap.io">help@hostcap.io</a>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

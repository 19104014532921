import React from 'react';
import { featureCardCarousel, services } from '../../constants';
import CustomButton from '../CustomButton';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import config from '../../config';

const Feature = () => {
  const slideLeft = () => {
    const slider = document.getElementById('slider');
    slider!.scrollLeft = slider!.scrollLeft - 500;
  };

  const slideRight = () => {
    const slider = document.getElementById('slider');
    slider!.scrollLeft = slider!.scrollLeft + 500;
  };

  return (
    <div className="relative lg:px-0">
      <img src="/assets/featureBgImage.svg" alt="background" width={1000} height={1000} className="absolute top-[-7%]" />
      <div className="pt-[81px] sm:px-16 lg:px-0 lg:pt-[100px]">
        <p className="w-[94px] text-headline-2 text-center text-secondary border border-gray-400 border-opacity-25 rounded-3xl py-[10px] ml-[20px] lg:ml-[100px]">
          FEATURES
        </p>
        <h3 className="lg:w-[544px] text-headline-1 pt-[29px] ml-[20px] lg:ml-[100px]">
          Comprehensive and trusted <br />
          solutions built for you.
        </h3>
        <div className="relative group flex flex-row px-1">
          <MdChevronLeft
            onClick={slideLeft}
            className="bg-black top-[50%] left-7 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-30  hidden group-hover:block"
            size={40}
          />
          <div
            id={'slider'}
            className="flex pl-[20px] lg:pl-[100px] lg:pr-[20px] gap-[16px] overflow-x-scroll scroll-smooth scrollbar-hide relative "
          >
            {featureCardCarousel.map(({ id, title, description, image }) => (
              <div
                key={id}
                className=" lg:h-[354px] bg-dark-03 bg-opacity-60 border border-gray-400 border-opacity-60 rounded-lg px-[17px] lg:px-[37px] py-[50px] mt-[42px]"
              >
                <img src={image} alt={title} width={100} height={100} />
                <h4 className="text-title-1">{title}</h4>
                <p className="w-[360px] lg:w-[456px] text-body-2 text-dark-02">{description}</p>
              </div>
            ))}
          </div>
          <MdChevronRight
            onClick={slideRight}
            className="bg-black top-[50%] right-5 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block"
            size={40}
          />
        </div>
      </div>
      <div className="flex justify-center items-center mt-[92px] lg:mt-[122px] px-[24px]">
        <div className="sm:px-16 px-[18px] py-6">
          <h3 className="text-headline-1 text-center">Do more with Hostcap</h3>
          <p className="lg:w-[385px] mx-auto text-center text-dark-02 mt-[13px]">
            At HostCap, we offer exclusive services designed to suit your financial demands.
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[62px] lg:gap-[117px] pb-[30px] lg:pb-0 mt-[44px] lg:mt-[95px]">
            {services.map(({ id, image, title, description, status }) => (
              <div key={id}>
                <img src={image} alt={title} width={100} height={100} className="mt-[]" />
                <div className="flex gap-2 pt-[28px]">
                  <h3 className="text-headline-3 ">{title}</h3>
                  <p className="text-secondary text-headline-2 pt-[13px]">{status}</p>
                </div>
                <p className="text-body-2 text-dark-02 mt-[2px]">{description}</p>
              </div>
            ))}
          </div>
          <a href={config.web.requestAccess} rel="noopener noreferrer">
            <div className="flex justify-center mt-[32px] lg:mt-[126px] mb-[64px] lg:mb-[110px] gap-1">
              <CustomButton
                title="GET STARTED"
                btnType="button"
                containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
                textStyles="text-button-1"
              />
              <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Feature;

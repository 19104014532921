const webUrl = process.env.REACT_APP_WEB_URL;

export default {
  web: {
    url: webUrl,
    signin: `${webUrl}/signin`,
    requestAccess: `${webUrl}/request-access`,
  },
  socials: {
    instagram: 'https://instagram.com/hostcap',
    twitter: 'https://twitter.com/hostcap',
    facebook: 'https://facebook.com/hostcap',
  },
};

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Footer, Header } from './components';
import Home from './app/page';
import Waas from './app/products/waas/page';
import GlobalSettlement from './app/products/global-settlement/page';
import VirtualCards from './app/products/virtual-cards/page';
import HostPay from './app/products/host+/page';
import Company from './app/company/page';
import Contact from './app/contact/page';
import LegalPrivacy from './app/legal/privacy-and-security/page';
import LegalTermsOfService from './app/legal/terms-of-service/page';
import PolicyAml from './app/compliance/AML-policy/page';
import PolicyCTF from './app/compliance/CTF-policy/page';
import 'react-toastify/dist/ReactToastify.min.css';
import './app/globals.scss';

export const App = () => (
  <>
    <ToastContainer autoClose={3000} hideProgressBar={true} closeButton={<>✕</>} position="top-center" />

    <Header />

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products/waas" element={<Waas />} />
      <Route path="/products/global-settlement" element={<GlobalSettlement />} />
      <Route path="/products/virtual-cards" element={<VirtualCards />} />
      <Route path="/products/host+" element={<HostPay />} />
      <Route path="/company" element={<Company />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/legal/terms-of-service" element={<LegalTermsOfService />} />
      <Route path="/legal/privacy-and-security" element={<LegalPrivacy />} />
      <Route path="/compliance/AML-policy" element={<PolicyAml />} />
      <Route path="/compliance/CTF-policy" element={<PolicyCTF />} />
    </Routes>

    <Footer />
  </>
);

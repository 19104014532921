import React from 'react';
import CustomButton from '../CustomButton';
import config from '../../config';

const MenuAction = () => {
  return (
    <div className="flex flex-col lg:flex-row lg:gap-[14px]">
      <a href={config.web.signin} rel="noopener noreferrer">
        <CustomButton title="Login" btnType="button" containerStyles="lg:mt-4" textStyles="text-body-1" />
      </a>
      <a href={config.web.requestAccess} rel="noopener noreferrer">
        <CustomButton
          title="GET STARTED"
          btnType="button"
          containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02 mt-2"
          textStyles="text-button-1 text-center"
        />
      </a>
    </div>
  );
};

export default MenuAction;

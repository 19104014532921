import React from 'react';
import CustomButton from '../CustomButton';
import config from '../../config';

const Hero = () => {
  return (
    <div className="relative pt-[174px] lg:pt-[200px]">
      <img
        src="/assets/starIcon.svg"
        alt="star icon"
        width={20}
        height={20}
        className="max-w-[1440px] absolute top-[10%] left-[50%] animate-bounce"
      />
      <img
        src="/assets/starIcon.svg"
        alt="star icon"
        width={20}
        height={20}
        className="max-w-[1440px] absolute top-[50%] lg:left-[3%] 2xl:left-[8%] animate-pulse"
      />
      <img
        src="/assets/star.svg"
        alt="star icon"
        width={20}
        height={20}
        className="max-w-[1440px] absolute top-[30%] right-[5%] animate-pulse"
      />
      <div className="relative flex flex-col lg:justify-center lg:items-center  sm:px-16 px-[18px] py-6 ">
        <div className="flex flex-col lg:flex-row lg:justify-between pt-[13px]">
          <div>
            <p className="w-[220px] text-secondary text-center text-headline-2 border border-gray-400 border-opacity-25 rounded-full px-[5px] py-[8px]">
              Trusted by 1000+ Businesses
            </p>
            <h2 className="text-headline-0 lg:text-headline lg:w-[675px] px-[2px]">
              Strategic Solutions To Accelerate Your Business Growth
            </h2>
            <div className="">
              <a href={config.web.requestAccess} rel="noopener noreferrer">
                <div className="flex gap-2 pt-[40px]">
                  <CustomButton
                    title="GET STARTED"
                    btnType="button"
                    containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
                    textStyles="text-button-1"
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <p className="lg:w-[522px] text-body-1 text-dark-02 border-l-[3px] border-secondary px-5 mt-[11px] lg:mt-0">
              We provide you with services that improve your business operations, while simplifying complex solutions.With our solutions and
              dynamic approach, which includes; global settlement solutions, an easy trading system, efficient payment solutions, and a lot
              more, we're here to fuel your journey, ensuring you reach your goals faster and stronger. Let's elevate your business growth,
              and provide unparalleled utility on your assets.
            </p>
          </div>
        </div>
      </div>
      <div className="mx-auto pt-[100px] lg:pt-[200px] sm:px-16 px-[18px]">
        <div className="flex justify-center items-center">
          <div className="">
            <h2 className="text-headline-1 text-center">Empowering Your Financial Journey:</h2>
            <p className="lg:w-[472px] text-center text-dark-02 text-body-1 mt-[25px]">
              With our focus on providing you with various ways to utilize your assets, we are able to equip you with the resources,
              insights, and tools necessary to take control of your finances and expand your business.
            </p>
            <img src="/assets/heroImg.png" alt="hero" width={500} height={500} className="mt-[26px] mx-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

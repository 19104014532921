import React, { useState } from 'react';
import { menuLinks } from '../../constants';
import { Link } from 'react-router-dom';

interface MenuLink {
  id: number;
}

interface HoveredLinks {
  [key: number]: boolean;
}

const Dropdown = () => {
  const [click, setClick] = useState(false);
  const [isHovered, setIsHovered] = useState<HoveredLinks>({});

  const handleMouseEnter = (id: number) => {
    setIsHovered((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleMouseLeave = (id: number) => {
    setIsHovered((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleClick = () => setClick(!click);

  return (
    <div>
      <span className="absolute">
        <p className="invisible">invisible</p>
      </span>
      <ul
        onClick={handleClick}
        className={
          // prettier-ignore
          click ? 'hidden' : ' absolute top-[73px] right-[30%] px-[5px] text-start bg-black border border-gray-400 border-opacity-30 rounded-xl group-hover:block'
        }
      >
        {menuLinks.map(({ id, title, description, url }) => (
          <li
            key={id}
            className="my-[3px] hover:bg-hoverBg hover:bg-opacity-50 hover:rounded-xl"
            onMouseEnter={() => handleMouseEnter(id)}
            onMouseLeave={() => handleMouseLeave(id)}
          >
            <Link to={url} onClick={() => setClick(false)}>
              <div className="flex gap-[25px] pt-[10px] pb-[20px] px-[12px]">
                <div className="">
                  <h2 className="text-headline-4">{title}</h2>
                  <p className="lg:w-[530px] text-body-4 text-dark-02 pt-[8px]">{description}</p>
                </div>
                <div className="mt-[18px]">
                  <img
                    src={isHovered[id] ? '/assets/arrowIcon.svg' : '/assets/arrowIconGray.svg'}
                    alt="arrow icon"
                    width={20}
                    height={20}
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;

/* eslint-disable quotes */
export const menuLinks = [
  {
    id: 1,
    title: 'Wallets as a Service',
    description: 'Take advantage of our WAAS infrastructure with personalized solutions built for you',
    url: '/products/waas',
  },
  {
    id: 2,
    title: 'Global Settlement',
    description: 'Make and receive payments internationally without worrying about border barriers',
    url: '/products/global-settlement',
  },
  {
    id: 3,
    title: 'Virtual Cards',
    description: 'Our virtual cards are specifically tailored to suit your business needs',
    url: '/products/virtual-cards',
  },
  {
    id: 4,
    title: 'Host+ Pay',
    description: 'We allow you to easily accept crypto payments and reach a global audience, while enjoying fast and secure transactions',
    url: '/products/host+',
  },
];

export const footerLinks = [
  {
    id: 1,
    title: 'Company',
    links: [
      { linkTitle: 'About Us', url: '/company' },
      { linkTitle: 'Contact Us', url: '/contact' },
    ],
  },
  {
    id: 2,
    title: 'Legal',
    links: [
      { linkTitle: 'Terms of Service', url: '/legal/terms-of-service' },
      { linkTitle: 'Privacy and Security', url: '/legal/privacy-and-security' },
    ],
  },
  {
    id: 3,
    title: 'Compliance',
    links: [
      { linkTitle: 'AML Policy', url: '/compliance/AML-policy' },
      { linkTitle: 'CFT Policy', url: '/compliance/CTF-policy' },
    ],
  },
];

export const featureCardCarousel = [
  {
    id: 1,
    image: '/assets/scaleUnbalanced.svg',
    title: 'OTC Services',
    description:
      'With our OTC trading service, you can securely buy or sell large volumes of cryptocurrencies. Through our network of trusted partners, we are able to provide access to a large liquidity pool, and execute trades without affecting the market',
  },
  {
    id: 2,
    image: '/assets/vault.svg',
    title: 'Custody & Insurance',
    description:
      'With top-notch security and insurance, your digital assets are in safe hands. Our advanced security measures ensure that your assets are protected 24/7. We also have insurance coverage as an extra layer of protection.',
  },
  {
    id: 3,
    image: '/assets/coin.svg',
    title: 'Host+ Trading',
    description:
      'One of our top priorities is to provide you with the best trading experience. We strive for the best possible trade executions by leveraging smart routine tactics, advanced execution algorithms and an extensive network of liquidity providers.',
  },
  {
    id: 4,
    image: '/assets/percent.svg',
    title: 'Host+ Lending',
    description:
      'You never had to go through the tedious process of lending and borrowing with HostCap. We equip you with the necessary tools and information needed to make lending and borrowing of digital assets as seamless as possible.',
  },
];

export const services = [
  {
    id: 1,
    image: '/assets/chartPie.svg',
    title: 'Portfolio Management:',
    description:
      'Our team is at your service for instant execution and consistent surveillance of your portfolio. We recognize the significance of portfolio management in reducing vulnerabilities and optimizing your assets, so we curate a diversified collection of assets that align with your goals, ensuring a profitable outcome. ',
    status: '',
  },
  {
    id: 2,
    image: '/assets/shieldCheck.svg',
    title: 'Safe Financial Choice:',
    description:
      'At HostCapital, we prioritize the security of your assets and personal details. Rest assured, we safeguard your assets and information with strict security measures. Your trust is important to us, and your financial well-being is our top priority.',
    status: '',
  },
  {
    id: 3,
    image: '/assets/moneyStack.svg',
    title: 'Staking',
    description:
      'Unlock a fresh opportunity to enhance your holdings when you stake your assets to earn rewards with us. Enjoy the benefit of passive income via our settlement feature, and you can earn up to 12% in rewards when you stake.',
    status: 'Coming soon',
  },
  {
    id: 4,
    image: '/assets/eye.svg',
    title: 'Transparency:',
    description:
      'We prioritize your satisfaction and provide you with accurate analysis regarding your assets. You can easily track your resources, and have full control of your transactions at no hidden costs. ',
    status: '',
  },
  {
    id: 5,
    image: '/assets/headphone.svg',
    title: 'Virtual Assistant',
    description:
      'We are committed to serving you. You can always execute trades even without logging into your app. We have provided a WhatsApp virtual assistant that understands your needs and can help carry out instructions securely.',
    status: '',
  },
  {
    id: 6,
    image: '/assets/circle-check.svg',
    title: 'Easy To Use',
    description:
      'Signing up on HostCap is easy, quick, and straightforward. You don’t have to worry about getting started, all you have to do is request access and we’ll be at your service.',
    status: '',
  },
];

export const products = [
  {
    id: 1,
    title: 'Wallets As A Service',
    description:
      'Store, manage, and transact digital assets effectively. We provide you with a platform to securely manage your digital assets, ensure your investments are safe, manage your cryptocurrency, and enable you to carry out transactions easily.',
    image: '/assets/walletAlt.svg',
    url: '/products/waas',
    bgColor: 'bg-gradient-to-r from-black via-advance to-black',
  },
  {
    id: 2,
    title: 'Global Settlement',
    description:
      'Make transactions easily across borders. Transacting internationally would no longer be a problem once you sign up with us at HostCap. We have eliminated the traditional hassle of transacting across borders, so now you can transact seamlessly without being restricted by geographical locations.',
    image: '/assets/globe.svg',
    url: '/products/global-settlement',
    bgColor: 'bg-gradient-to-r from-black via-tertiary to-black',
  },
  {
    id: 3,
    title: 'Virtual Cards',
    description:
      'Create and manage your virtual cards conveniently with HostCapital. Control your virtual cards, make bulk payments, pay for services, support your favorite creators, shop internationally, and manage all your business expenses effectively with our specialized virtual cards services.',
    image: '/assets/creditCard.svg',
    url: '/products/virtual-cards',
    bgColor: 'bg-gradient-to-r from-black via-tertiary to-black',
  },
  {
    id: 4,
    title: 'Host+ Pay',
    description: 'Unlock a range of endless financial possibilities with us.',
    image: '/assets/plusLarge.svg',
    url: '/products/host+',
    bgColor: 'bg-gradient-to-r from-black via-advance to-black',
  },
];

export const globalSettlement = [
  {
    id: 1,
    image: '/assets/moneyInsert.svg',
    title: 'Pay Suppliers',
    description: 'Upgrade your business flow by paying your suppliers swiftly, and building trust.',
  },
  {
    id: 2,
    image: '/assets/arrowDownLeft.svg',
    title: 'Collect Payments',
    description: 'Simplify cross-border transactions and instantly convert between currencies at the latest exchange rates.',
  },
  {
    id: 3,
    image: '/assets/squareCode.svg',
    title: 'Embed into Your Product',
    description: 'Elevate your business with our easily accessible APIs.',
  },
  {
    id: 4,
    image: '/assets/switchVertical.svg',
    title: 'Convert Between Currencies',
    description: 'Simplify cross-border transactions and instantly convert between currencies at the latest exchange rates.',
  },
];

export const countries = [
  {
    id: 1,
    image: '/assets/aed.svg',
    title: 'AED',
  },
  {
    id: 2,
    image: '/assets/ars.svg',
    title: 'ARS',
  },
  {
    id: 3,
    image: '/assets/aud.svg',
    title: 'AUD',
  },
  {
    id: 4,
    image: '/assets/bdt.svg',
    title: 'BDT',
  },
  {
    id: 5,
    image: '/assets/brl.svg',
    title: 'BRL',
  },
  {
    id: 6,
    image: '/assets/cny.svg',
    title: 'CNY',
  },
  {
    id: 7,
    image: '/assets/eur.svg',
    title: 'EUR',
  },
  {
    id: 8,
    image: '/assets/gbp.svg',
    title: 'GBP',
  },
  {
    id: 9,
    image: '/assets/ghc.svg',
    title: 'GHC',
  },
  {
    id: 10,
    image: '/assets/idr.svg',
    title: 'IDR',
  },
  {
    id: 11,
    image: '/assets/inr.svg',
    title: 'INR',
  },
  {
    id: 12,
    image: '/assets/jpy.svg',
    title: 'JPY',
  },
  {
    id: 13,
    image: '/assets/kes.svg',
    title: 'KES',
  },
  {
    id: 14,
    image: '/assets/mwk.svg',
    title: 'MWK',
  },
  {
    id: 15,
    image: '/assets/mxn.svg',
    title: 'MXN',
  },
  {
    id: 16,
    image: '/assets/myr.svg',
    title: 'MYR',
  },
  {
    id: 17,
    image: '/assets/ngn.svg',
    title: 'NGN',
  },
  {
    id: 18,
    image: '/assets/php.svg',
    title: 'PHP',
  },
  {
    id: 19,
    image: '/assets/rwf.svg',
    title: 'RWF',
  },
  {
    id: 20,
    image: '/assets/sgd.svg',
    title: 'SGD',
  },
  {
    id: 21,
    image: '/assets/thb.svg',
    title: 'THB',
  },
  {
    id: 22,
    image: '/assets/tzs.svg',
    title: 'TZS',
  },
  {
    id: 23,
    image: '/assets/ugx.svg',
    title: 'UGX',
  },
  {
    id: 24,
    image: '/assets/vnd.svg',
    title: 'VND',
  },
  {
    id: 25,
    image: '/assets/xaf.svg',
    title: 'XAF',
  },
  {
    id: 26,
    image: '/assets/xof.svg',
    title: 'XOF',
  },
  {
    id: 27,
    image: '/assets/zar.svg',
    title: 'ZAR',
  },
  {
    id: 28,
    image: '/assets/zmw.svg',
    title: 'ZMW',
  },
];

export const virtualCardServices = [
  {
    id: 1,
    image: '/assets/credit-card.svg',
    title: 'Multiple Card Creation',
    content: 'Generate and manage multiple cards, tailored to specific expense categories',
  },
  {
    id: 2,
    image: '/assets/moneyStack.svg',
    title: 'Set Spending Limits',
    content: 'Control your spending by setting and adjusting spending limits that align with your budget and financial goals',
  },
  {
    id: 3,
    image: '/assets/lock-alt.svg',
    title: 'Lock Cards',
    content:
      'You can lock a card to prevent any unauthorized transactions, and unlock it when you’re ready to use, ensuring that funds are safe.',
  },
  {
    id: 4,
    image: '/assets/users.svg',
    title: 'Issue Cards To Your Employees',
    content:
      'With our virtual cards services, you can easily register and allocate cards to your employees, making expense management more efficient',
  },
];

export const walletServices = [
  {
    id: 1,
    image: '/assets/code.svg',
    title: 'Seamless Integration And Operations',
    description:
      'Our API eliminates the need for complex development cycles, as you can seamlessly integrate it into your existing systems, while ensuring that you save time and resources.',
  },
  {
    id: 2,
    image: '/assets/shieldCheck.svg',
    title: 'Safe And Secure',
    description:
      'Designed with your safety in mind, our solution comes with advanced security measures that protect your wallet from potential threats or attacks. We ensure that your security is always prioritized.',
  },
  {
    id: 3,
    image: '/assets/moneyStack.svg',
    title: 'Advanced Wallet Customization',
    description:
      "You can personalize your wallet experience to match your preferences, or your business model. Choose your themes, layouts, and features to create a wallet that's uniquely yours. Enjoy the simplicity of a wallet service designed with you in mind.",
  },
  {
    id: 4,
    image: '/assets/chartPie.svg',
    title: 'Be In Control',
    description:
      'You have full control over your wallet. You can oversee transactions, manage wallet features, track rates and do so much more with your wallet.',
  },
  {
    id: 5,
    image: '/assets/link-alt-1.svg',
    title: 'Multi-chain Support',
    description:
      'Elevate your business experience with the ability to access diverse blockchain networks and explore new horizons with our wallets-as-service APIs.',
  },
];

export const hostPlusServices = [
  {
    id: 1,
    image: '/assets/code.svg',
    title: 'Integrate Widget:',
    description:
      "With this feature, businesses can integrate the Host+ Payment widget onto their website, and watch as their payment experience transforms. Whether you're a user or a merchant, Host+Pay empowers you to effortlessly receive crypto payments from your customers, straight into your wallet.",
  },
  {
    id: 2,
    image: '/assets/scan.svg',
    title: 'Scan Barcode',
    description:
      "Customers can scan the barcode displayed on your website to make their payments. It's as easy as scanning a code with their mobile devices and making payments, providing them with a quick and hassle-free payment experience.",
  },
  {
    id: 3,
    image: '/assets/payment-link.svg',
    title: 'Generate Payment Links',
    description:
      'For businesses looking for even more flexibility, Host+Pay lets you generate payment links for your customers. You can create custom payment links that you can share through various channels, making it convenient for customers to complete their transactions.',
  },
];

import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export const handleRequestErrors = (error: AxiosError, defaultMessage?: string) => {
  let useMessage = '';

  if (error && error.response && error.response.status.toString().startsWith('4')) {
    const errors = (error.response as any).data.errors;
    useMessage = (error.response as any).data.message || (errors && errors[0].message) || defaultMessage || 'An unexpected error occurred.';
  }

  toast.error(useMessage);
};

import React from 'react';
import { AboutProduct, Feature, Hero, JoinUs } from '../components';

export default function Home() {
  return (
    <div className="relative">
      <section className="">
        <Hero />
      </section>
      <section className=" bg-black bg-opacity-70">
        <Feature />
      </section>
      <section id="products" className="bg-dark-04">
        <AboutProduct />
      </section>
      <section className="bg-black">
        <JoinUs />
      </section>
    </div>
  );
}

import React from 'react';
import CustomButton from '../CustomButton';
import Offers from './Offers';
import config from '../../config';

const AboutProduct = () => {
  return (
    <div className="max-w-[1440px] mx-auto sm:px-16 px-[18px] py-6">
      <div className=" relative h-full flex flex-col lg:flex-row items-start gap-[31px] lg:gap-[0] justify-between pt-[61px] lg:pt-[215px]">
        <div className="lg:sticky w-full h-full lg:top-[15%]">
          <h2 className="text-headline-1">Know More About HostCap</h2>
          <p className="lg:w-[425px] text-dark-02 text-body-1">
            There’s no limit to how much you can do with HostCap! We offer a range of financial services.
          </p>
        </div>
        <div className="">
          <div className="hidden bg-gradient-to-r from-black via-tertiary to-black">
            <p className="bg-gradient-to-r from-black via-advance to-black">h</p>
          </div>
          <Offers />
        </div>
      </div>
      <div className="flex flex-col justify-center mt-[125px] lg:mt-[232px]">
        <h2 className="text-center text-headline-1">Expand Your Product Offering:</h2>
        <p className="lg:w-[627px] mx-auto text-center text-body-1 text-dark-02 mt-[13px]">
          We provide you with reliable products needed to expand your business and increase revenue efficiently. As a team, we offer our
          customers with the information and tools necessary to increase business productivity.
        </p>
        <div className="flex justify-center gap-1 mt-[23px]">
          <a href={config.web.requestAccess} rel="noopener noreferrer">
            <CustomButton
              title="GET STARTED"
              btnType="button"
              containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
              textStyles="text-button-1"
            />
          </a>
          <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
        </div>
        <div className="relative w-[338px] lg:w-[354px] mx-auto mt-[32px]">
          <div className="relative mb-[120px]">
            <img src="/assets/select-amount.svg" alt="virtual-card" width={544} height={500} className="z-20" />
            <img src="/assets/ballIcon.svg" alt="ball icon" width={300} height={300} className="absolute top-[25%] lg:right-[70%] z-10" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutProduct;

import CustomButton from '../../components/CustomButton';
import React from 'react';

export default function Contact() {
  return (
    <div>
      <section className="relative max-w-[1400px] mx-auto pt-[100px] lg:pt-[160px] sm:px-16 px-[18px] pb-[30px]">
        <div className="flex flex-col justify-center items-center">
          <p className="text-secondary text-center text-body-2 border border-gray-400 border-opacity-30 rounded-full px-[20px] py-[4px]">
            CONTACT US
          </p>
          <h2 className="text-headline-0 lg:w-[689px] lg:text-headline text-center pt-[24px]">Reach out to us</h2>
          <p className="lg:w-[493px] text-body-1 text-center text-dark-02 pt-[10px]">
            Begin your journey to financial excellence with us by reaching out to via our contact information below. For questions or
            concerns, please contact our 24/7 support team, we are always available to respond to you, as your satisfaction is our priority.
          </p>
          <a href="mailto:help@hostcap.io" rel="noopener noreferrer">
            <div className="flex gap-1 mt-[20px] justify-center items-center lg:justify-start lg:items-start">
              <CustomButton
                title="CONTACT US"
                btnType="button"
                containerStyles="bg-dark-01 px-4 pt-2 pb-3 rounded-full hover:bg-dark-02"
                textStyles="text-button-1"
              />
              <img src="/assets/arrowIcon.svg" alt="arrow icon" width={50} height={40} className="bg-primary p-[8px] rounded-full" />
            </div>
          </a>
        </div>
      </section>
      <section className="flex justify-center items-center gap-9 md:gap-0 pb-[50px] lg:pb-[153px] pt-[30px] lg:pt-[108px]">
        <img src="/assets/contactImage.svg" alt="contact" width={1000} height={1000} className="hidden md:block" />

        <div className="md:absolute lg:left-[20%] 2xl:left-[23%] left-[10%]">
          <a href="mailto:help@hostcap.io">
            <img src="/assets/mail.svg" alt="mail-icon" width={60} height={60} />
            <p className="text-headline-4">Email Us</p>
            <p className="text-body-3 text-white-01">Support@hostcap.io</p>
          </a>
        </div>
        <div className="md:absolute lg:right-[24%] 2xl:right-[30%] right-[8%]">
          <img src="/assets/mobile.svg" alt="mail-icon" width={60} height={60} />
          <p className="lg:text-headline-4 ">Call Us</p>
          <p className="text-body-3 text-white-01">+234 123 4567 890</p>
        </div>
      </section>
    </div>
  );
}
